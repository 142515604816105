import React, { useEffect, useState } from 'react';
import { Animated } from 'react-animated-css';

function AnimatedVisibility({ isVisible, children }) {
  const [removeFromDOM, setRemoveFromDOM] = useState(!isVisible);
  useEffect(() => {
    if (!isVisible) setTimeout(() => setRemoveFromDOM(true), 750);
    else setRemoveFromDOM(false);
  }, [isVisible]);

  const style = removeFromDOM ? { display: 'none' } : null;
  return (
    <Animated animationIn="zoomIn" animationOut="zoomOut" isVisible={isVisible} style={style}>
      {children}
    </Animated>
  );
}
export default AnimatedVisibility;
